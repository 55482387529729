/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Version: 4.1.0
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Main Css File
*/


//Fonts
@import "../../fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-custom";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins";


// Structure
@import "../../structure/topbar";
@import "../../structure/page-head";
@import "../../structure/footer";
@import "../../structure/vertical";
@import "../../structure/horizontal";
@import "../../structure/two-column";
@import "../../structure/layouts";

// Components
@import "../../components/waves";
@import "../../components/avatar";
@import "../../components/accordion";
@import "../../components/helper";
@import "../../components/preloader";
@import "../../components/forms";
@import "../../components/widgets";
@import "../../components/demos";
@import "../../components/print";
@import "../../components/ribbons";
@import "../../components/toast";
@import "../../components/scrollspy";

// Bootstrap components
@import "../../components/root";
@import "../../components/reboot";
@import "../../components/alerts";
@import "../../components/badge";
@import "../../components/buttons";
@import "../../components/breadcrumb";
@import "../../components/card";
@import "../../components/dropdown";
@import "../../components/nav";
@import "../../components/table";
@import "../../components/modal";
@import "../../components/pagination";
@import "../../components/progress";
@import "../../components/popover";
@import "../../components/type";
@import "../../components/form-check";
@import "../../components/form-control";
@import "../../components/list-group";

// Plugins
@import "../../plugins/custom-scrollbar";
@import "../../plugins/prismjs";
@import "../../plugins/sweetalert2";
@import "../../plugins/dropzone";
@import "../../plugins/range-slider";
@import "../../plugins/sortablejs";
@import "../../plugins/tour";
@import "../../plugins/swiper";
@import "../../plugins/multijs";
@import "../../plugins/colorpicker";
@import "../../plugins/filepond";
@import "../../plugins/form-input-spin";
@import "../../plugins/ckeditor";
@import "../../plugins/quilljs";
@import "../../plugins/gridjs";
@import "../../plugins/listjs";
@import "../../plugins/apexcharts";
@import "../../plugins/chartjs";
@import "../../plugins/echarts";
@import "../../plugins/google-map";
@import "../../plugins/autocomplete";
@import "../../plugins/vector-maps";
@import "../../plugins/leaflet-maps";
@import "../../plugins/fullcalendar";
@import "../../plugins/emoji-picker";
@import "../../plugins/datatables";
@import "../../plugins/select2";

// Plugins
@import "../../plugins/toastify";
@import "../../plugins/choices";
@import "../../plugins/flatpicker";
@import "../../plugins/flag-input";
@import "../../plugins/form-wizard";

// Pages
@import "../../pages/authentication";
//@import "../../pages/dashboard";
@import "../../pages/timeline";
@import "../../pages/gallery";
@import "../../pages/errors";
@import "../../pages/profile";
@import "../../pages/sitemap";
@import "../../pages/team";
@import "../../pages/coming-soon";
@import "../../pages/search-results";
@import "../../pages/ecommerce";
@import "../../pages/invoice";
@import "../../pages/chat";
@import "../../pages/email";
@import "../../pages/kanban";
//@import "../../pages/landing";
//@import "../../pages/nft-landing";
@import "../../pages/file-manager";
@import "../../pages/to-do";
@import "../../pages/jobs";
@import "../../pages/job-landing";


// custonm
@import "./angular-custom";

// ngx datatable
/* You can add global styles to this file, and also import other style files */
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';

// jsoneditor
@import "node_modules/jsoneditor/dist/jsoneditor.min.css";

// RTL Set
// @import "../../rtl/components-rtl";
// @import "../../rtl/layouts-rtl";
// @import "../../rtl/pages-rtl";
// @import "../../rtl/plugins-rtl";


.ngDisabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: rgba(#9b9b9b, 0.1) !important;
  border: 1px solid #ced4da;
}

.text-disabled {
  color: #878a99;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: white !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 39px !important;
}

.ng-select_custom_error.ng-invalid .ng-select-container {
  border: 1px solid #f7666e !important;
  box-shadow: inset 0 0 0 rgb(0 0 0 / 8%), 0 0 0 3px #ffffff !important;
  outline: medium none invert !important;
  outline: initial !important;
  background: #f8f9fa;
  color: #0d2c35;
}

.custom-error-ngselect {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f7666e;
}


.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #f7666e;
}

.datatable-header {
  display: flex !important;
  align-items: center;

  .datatable-header-cell-label {
    font-weight: 600;
    white-space: break-spaces;
  }

  .datatable-header-cell {
    display: flex !important;
    align-items: center;
  }

  .datatable-header-cell-template-wrap {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }

  --vz-text-opacity: 1;
  color: var(--vz-secondary-color) !important;
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.datatable-row-wrapper {
  white-space: break-spaces;
  display: flex;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: #0000000d;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;
}

.datatable-body-cell {
  padding: 10px 0;
}

.datatable-body-row {
  width: 100% !important;
}

.datatable-scroll {
  width: 100% !important;
}

.jsonEditor {
  width: 100%;
}

.datatable-footer-inner {
  width: 80%;
  display: flex;
  padding: 15px 15px;
  justify-content: space-between;
}

audio::-webkit-media-controls-panel {
  background-color: #ffdd00;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(91, 193, 238, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(91, 193, 238, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(91, 193, 238, 0);
  }
}
.call-label-status{
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: wrap;
  .btn-sm{
    --vz-btn-padding-y: 0.1rem;
    --vz-btn-padding-x: 0.5rem;
    --vz-btn-font-size: 0.765625rem;
  }
  .time-label{
    font-size: 0.88rem;
  }
}

.toast-modal {
  position: fixed;
  top: 15%;
  right: 1%;
  width: 100%;
  z-index: 1031;
  .toast{
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .btn {
    animation: pulse 1.5s ease-in-out infinite;
  }
}

