/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

img.sm-logo-main {
  display: block;
  height: 32px;
  background: url("/assets/images/dlaw/logo-sm.png") no-repeat center center;
  background-size: cover;
  content: url("/assets/images/dlaw/logo-sm.png");
}

img.lg-logo-main {
  display: block;
  margin-top: 5px;
  height: 28px;
  background: url("/assets/images/dlaw/logo-white-lg.png") no-repeat center center;
  background-size: cover;
  content: url("/assets/images/dlaw/logo-white-lg.png");
}
.navbar-menu{
  background: #161616;
  border-right: #494949;
}

/***Login**/

.logo-login{
  display: block;
  margin: 0 auto;
  height: 240px;
  background: url("/assets/images/dlaw/logo-primary-white.png") no-repeat center center;
  background-size: cover;
  content: url("/assets/images/dlaw/logo-primary-white.png");
}
