/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import "node_modules/jsoneditor/dist/jsoneditor.min.css";

// Swiper
.coverflowslide .slide {
  position: relative;
  transition: transform 0.3s;
}

.coverflowslide .slick-list {
  overflow: hidden !important;
}

.coverflowslide .slick-center {
  transform: scale(1.2); // Adjust the scale factor for the "Coverflow" effect
  z-index: 1;
}

.coverflowslide .carousel {
  width: 100%;
  max-width: 600px; // Adjust the carousel width as needed
  margin: 0 auto;
}

.space .slick-slide {
  margin: 10px;
}

#notificationItemsTabContent {
  .avatar-title {
    height: 30px !important;
    width: 30px !important;
  }
}


[data-bs-theme="dark"] {
  .fc {
    .fc-multimonth-daygrid {
      background: #212529 !important;

    }

    .fc-day-disabled {
      background: #282b2e !important;
    }

    .fc-multimonth {
      border-color: #32383e !important;
    }
  }
}

#landingnft {
  .slick-dots {
    bottom: -36px;
    z-index: 1;

    .slick-active {
      button {
        background-color: #3577f1 !important;
      }
    }
  }

  .slick-dots li {
    height: 7px !important;
  }

  .slick-dots li button {
    width: 22px !important;
    height: 5px !important;
    border: none;
    background-color: rgba(109, 98, 253, .2) !important;
    padding: 0;
    margin: 0;
    transition: all .3s;
    border-radius: 50px;

    &::before {
      display: none;
    }
  }
}

.ngDisabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: rgba(#9b9b9b, 0.1) !important;
  border: 1px solid #ced4da;
}

.text-disabled {
  color: #878a99;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: white !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 39px !important;
}

.ng-select_custom_error.ng-invalid .ng-select-container {
  border: 1px solid #f7666e !important;
  box-shadow: inset 0 0 0 rgb(0 0 0 / 8%), 0 0 0 3px #ffffff !important;
  outline: medium none invert !important;
  outline: initial !important;
  background: #f8f9fa;
  color: #0d2c35;
}

.custom-error-ngselect {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f7666e;
}


.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #f7666e;
}

.datatable-header {
  display: flex !important;
  align-items: center;

  .datatable-header-cell-label {
    font-weight: 600;
    white-space: break-spaces;
  }

  .datatable-header-cell {
    display: flex !important;
    align-items: center;
  }

  .datatable-header-cell-template-wrap {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }

  --vz-text-opacity: 1;
  color: var(--vz-secondary-color) !important;
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.datatable-row-wrapper {
  white-space: break-spaces;
  display: flex;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: #0000000d;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;
}

.datatable-body-cell {
  padding: 10px 0;
}

.datatable-body-row {
  width: 100% !important;
}

.datatable-scroll {
  width: 100% !important;
}

.jsonEditor {
  width: 100%;
}

.datatable-footer-inner {
  width: 80%;
  display: flex;
  padding: 15px 15px;
  justify-content: space-between;
}

audio::-webkit-media-controls-panel {
  background-color: #ffdd00;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(91, 193, 238, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(91, 193, 238, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(91, 193, 238, 0);
  }
}

.call-label-status {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: wrap;

  .btn-sm {
    --vz-btn-padding-y: 0.1rem;
    --vz-btn-padding-x: 0.5rem;
    --vz-btn-font-size: 0.765625rem;
  }

  .time-label {
    font-size: 0.88rem;
  }
}

.toast-modal {
  position: fixed;
  top: 15%;
  right: 1%;
  width: 100%;
  z-index: 1031;

  .toast {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .btn {
    animation: pulse 1.5s ease-in-out infinite;
  }
}

.datatable-row-left {
  display: flex;
  background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;

  .datatable-body-cell {
    display: flex;
    background-color: var(--vz-secondary-bg);
  }
}